import axios from "axios";
import { JSON_CONTENT_TYPE } from "@api/doinsport/config";
export const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

export const httpClient = (baseURL = VUE_APP_BASE_URL) => axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": JSON_CONTENT_TYPE },
});

export default httpClient(VUE_APP_BASE_URL);
