export default {
  variations: [],
  isLoadedVariations: false,
  selectedTab: "confirmed",
  updatedStartAt: false,
  firstLoad: true,
  lastBlockPrice: null,
  loadedLessons: false,
  loadedParticipants: [],
};
