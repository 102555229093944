const path = "events";

export default [
  {
    path: path,
    name: "events",
    component: () => import("@views/matches/MatchesList.vue"),
    meta: { title: "data.menu.events.meta.title" },
  },
  {
    path: path + "/:id",
    name: "event",
    component: () => import("@views/matches/MatchDisplay.vue"),
  },
];