import axios from "axios";

export default {
  authRequest (state) {
    state.status = "loading";
  },
  authSuccess (state, token) {
    state.status = "success";
    state.token = token;
  },
  setConnecting (state, payload) {
    state.connecting = payload;
  },
  authMe (state, user) {
    state.user = user;
    state.status = "success";
  },
  selectedClub (state, club) {
    state.club = club;
  },
  authError (state) {
    state.status = "error";
  },
  logout (state) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedClub");
    localStorage.removeItem("default-club");
    localStorage.removeItem("current-club");
    localStorage.removeItem("enzocard-token");
    localStorage.removeItem("refresh_token");
    delete axios.defaults.headers.common["Authorization"];
    state.status = "";
    state.token = "";
  },
};
